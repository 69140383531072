<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="12">
        <span class="text-14">{{ orderInfo.advertiserName}}，{{htmlToSchedule(demandStartDate,demandEndDate)}}</span>
      </i-col>
      <!-- <i-col
        span="12"
        class="text-right"
      >
        <Button
          type="success"
          size="small"
          @click="handleSubmitTask"
        >提交全部任务</Button>
      </i-col> -->
    </Row>
    <div class="m-b-10">
      <RadioGroup
        type="button"
        size="small"
        v-model="query.productId"
        @on-change="changeProduct"
      >
        <Radio
          :label="item.productId"
          v-for="item in demandProductArray"
          :key="item.productId"
        >{{item.productName}}</Radio>
      </RadioGroup>
    </div>
    <div>
      <Table
        stripe
        size="small"
        ref="selection"
        :data="list"
        :columns="showColumns"
        :loading="dataLoading"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          show-elevator
          show-sizer
          :page-size-opts="pagesizeSetting"
          @on-page-size-change='changePageSize'
          @on-change="changePage"
          :current="query.pageNumber"
        ></Page>
      </div>

    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{feedbackTitle}} </p>
      <img-light-box
        ref="imgLightBox"
        :showModal="visiblePreview"
        :sortIndex.sync="sortIndex"
      ></img-light-box>
    </Modal>

  </div>
</template>

<script>
import { demandMixins } from '@/assets/mixins/demand'
import { formartMspTaskitemStatus } from '@/utils/tagStatus'
import ImgLightBox from '@/components/common/ImgVideoLightBox'

import { getProductList } from '@/api/msp/task'
import { getNextTaskitemPage } from '@/api/msp/taskitem'
import { getTaskitemAttachList } from '@/api/msp/demandV3'
export default {
  mixins: [demandMixins],
  components: { ImgLightBox },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        taskId: null,
        productId: null
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      list: [],
      total: 0,
      demandProductArray: [],
      showColumns: [
        { title: '资源编号', key: 'code', minWidth: 120, align: 'center' },
        { title: '所属站台', key: 'stationName', minWidth: 100, align: 'center' },
        { title: '位置', key: 'positionName', align: 'center', minWidth: 80 },
        { title: '当前上刊作业日期', key: 'actionDate', align: 'center', minWidth: 100 },
        {
          title: '当前上刊任务状态',
          key: 'status',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            return h('div', [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName)
            ])
          }
        },
        { title: '下刊作业日期', key: 'nextActionDate', align: 'center', minWidth: 100 },
        {
          title: '下刊作业状态',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              formartMspTaskitemStatus(h, params.row.nextStatus, params.row.nextStatusName)
            ])
          }
        },
        {
          title: '素材反馈',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            if (params.row.nextStatus === 3) {
              return h('a', {
                on: {
                  click: () => {
                    this.onViewFeedback(params.row)
                  }
                }
              }, '素材查看')
            } else {
              return null
            }
          }
        }
      ],
      dataLoading: false,
      visiblePreview: false,
      sortIndex: 0,
      feedbackTitle: ''
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.installSetting.currentTaskId
    },
    demandType () {
      return this.$store.state.installSetting.demandType
    }
  },
  created () {
    this.getDemandProductData()
  },
  methods: {
    getTableData () {
      this.dataLoading = true
      this.query.taskId = this.curTaskId
      getNextTaskitemPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.dataLoading = false })
    },
    getDemandProductData () {
      getProductList({ taskId: this.curTaskId }).then(res => {
        if (res && !res.errcode) {
          this.demandProductArray = res
          if (this.demandProductArray && this.demandProductArray.length) {
            this.query.productId = this.demandProductArray[0].productId
            this.changeProduct()
          }
        } else {
          this.demandProductArray = []
        }
      })
    },
    changeProduct () {
      this.query.pageNumber = 1
      this.$store.commit('set_selected_productId', this.query.productId)
      this.getTableData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.getTableData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onViewFeedback (params) {
      getTaskitemAttachList({ taskitemId: params.nextTaskitemId }).then(res => {
        if (res && !res.errcode) {
          const postData = {
            startIndex: 0,
            imgList: res
          }
          this.sortIndex = 0
          this.feedbackTitle = `${params.code} (${res[0].uploadTime})`
          this.$refs.imgLightBox.init(postData)
          this.visiblePreview = true
        }
      })
    }
  }

}
</script>
